<template>
  <div class="gigawhat-estimation_tab">
    <p class="gigawhat-tab_description">Adresse des travaux:</p>
    <div class="address-row">
      <Address
        :addressValidator="addressValidator"
        :isVIEWER="isVIEWER"
        @onZipCodeChange="fetchCityByZipCode"
        @onCityChange="updatePrecarities"
      ></Address>
      <div class="giga-fields">
        <div
          class="beneficiary-group giga-input_field col-lg-8"
          v-if="isAnArtisan"
        >
          <label class="input-field_label beneficiary-label"
            >{{ $t("beneficiary_type") }}:</label
          >
          <div class="label-group">
            <label class="radio-style">
              <input
                type="radio"
                v-model="$v.beneficiary.isMoral.$model"
                :value="false"
              />
              Personne physique
            </label>
            <label class="radio-style">
              <input
                type="radio"
                v-model="$v.beneficiary.isMoral.$model"
                :value="true"
              />
              Personne morale
            </label>
          </div>
        </div>
      </div>
      <BeneficiaryPhysical
        v-if="isAnArtisan && !$v.beneficiary.isMoral.$model"
        :isVIEWER="isVIEWER"
        :isEdit="isEdit"
        :beneficiary="$v.beneficiary.physical"
      >
      </BeneficiaryPhysical>
      <BeneficiaryMoral
        v-if="isAnArtisan && $v.beneficiary.isMoral.$model"
        :isVIEWER="isVIEWER"
        :isEdit="isEdit"
        :beneficiary="$v.beneficiary.moral"
      >
      </BeneficiaryMoral>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { helpers } from "@/shared/helper";
import { estimationService } from "@/views/Simulations/services";
import { constants } from "@/shared/constants";
import BeneficiaryMoral from "@/views/Simulations/BeneficiaryMoral.vue";
import BeneficiaryPhysical from "@/views/Simulations/BeneficiaryPhysical.vue";
import Address from "@/views/Simulations/Address.vue";

export default {
  name: "BeneficiaryStep",
  components: {
    BeneficiaryMoral,
    BeneficiaryPhysical,
    Address
  },
  props: [
    "$v",
    "addressValidator",
    "isVIEWER",
    "isAnArtisan",
    "beneficiary",
    "ficheValidator"
  ],
  computed: {
    ...mapGetters("account", ["getCurrentUser"]),
    ...mapGetters("simulation", ["getSimulation", "isEdit"])
  },
  data() {
    return {
      departments: constants.DEPARTEMENTS
    };
  },
  methods: {
    fetchCityByZipCode(addressValidator) {
      estimationService.fetchCityByZipCode(
        this.$v.tabsFiche,
        this.departments,
        addressValidator
      );
    },
    updatePrecarities(addressValidator) {
      estimationService.updatePrecarities(this.$v.tabsFiche, addressValidator);
    }
  },
  async mounted() {}
};
</script>
<style scoped>
.content-div {
  word-wrap: break-word;
  max-height: 125.4px;
  min-height: 125.4px;
  overflow-y: auto;
}
</style>
